<template>
  <div class="card vary-card">
    <div class="heading-wrapper card-body heading flex-grow-0">
      <div>
        <h3 v-if="title !== ''">
          {{ title }}
        </h3>
      </div>
      <div>
        <p v-if="description !== ''" class="description">
          {{ description }}
        </p>
      </div>
    </div>
    <div class="card-body vary-card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VaryCard',
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      cls: {
        type: String,
        default: 'col'
      },
      button: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        buttonDefaultOptions: {
          variant: 'primary',
          size: 'md',
          class: 'padding-left-60 padding-right-60'
        }
      }
    },
    computed: {
      buttonOptions () {
        return {
          ...this.buttonDefaultOptions,
          ...this.button
        }
      }
    }
  }
</script>
